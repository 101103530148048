import Bugsnag, { BrowserConfig } from '@bugsnag/js';

const mapFeatureFlagsToBugsnag = (flags: Record<string, boolean | null>) =>
  Object.keys(flags)
    .filter((flag) => flags[flag])
    .map((flag) => ({ name: flag }));

const options: BrowserConfig = {
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  releaseStage: import.meta.env.VITE_BUGSNAG_RELEASE_STAGE,
  appVersion: import.meta.env.VITE_BUGSNAG_APP_VERSION,
  onError: (event) => {
    const thrownError = event.originalError;
    if (!thrownError || !thrownError.cause) {
      return;
    }

    event.addMetadata('cause', thrownError.cause);
  }
};

if (options.apiKey && options.releaseStage && options.appVersion) {
  Bugsnag.start(options);
  Bugsnag.addFeatureFlags(mapFeatureFlagsToBugsnag(window.Hobbii?.flags || {}));
}

window._bugsnag = Bugsnag;
